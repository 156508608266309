import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import checkList from "../../static/images/slider-additionally/check-list.svg";
import clock from "../../static/images/slider-additionally/clock.svg";
import money from "../../static/images/slider-additionally/money.svg";
import percent from "../../static/images/slider-additionally/percent.svg";
import target from "../../static/images/slider-additionally/target.svg";
import leftSlider from "../../static/images/slider-arrows/left.svg";
import rightSlider from "../../static/images/slider-arrows/right.svg";
import CatalogSectionAd from "../components/Catalog/CatalogSectionAd";
import CatalogSectionContainer from "../components/Catalog/CatalogSectionContainer";
import CatalogSectionMenu from "../components/Catalog/CatalogSectionMenu";
import CatalogSectionProduct from "../components/Catalog/CatalogSectionProduct";
import PageBox from '../components/PageBox';
import SEO from '../components/SEO';

IndexPage.defaultProps = {
  view: {},
  data: {},
}

IndexPage.propTypes = {
  data: PropTypes.object
}

export default function IndexPage({ data }) {
  const style = {
    wrapperPage: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: { xs: 'wrap', sm: 'wrap', lg: 'nowrap' },

      '& .infBlock': {
        display: {
          xs: 'block',
          sm: 'block',
          lg: 'none',
        }
      }
    },
    sideBar: {
      width: '100%',
      maxWidth: { sm: '100%', lg: '300px' },
      paddingRight: { sm: '0', lg: '31px' },

      '& > .infBlock': {
        display: { xs: 'none', sm: 'none', lg: 'block' }
      }
    },
    content: {
      width: {
        xs: '100%',
        sm: '100%',
        lg: 'calc(100% - 300px)'
      },
      marginBottom: {
        lg: '46px',
      }
    },
    wrapperChangeView: {
      color: '#6E7884',
      padding: '0',
      display: { xs: 'none', sm: 'none', lg: 'flex' },
      width: '60px',
      justifyContent: 'space-between',
    },
    wrapperPseudoButton: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '24px',
      height: '24px',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      cursor: 'pointer',

      '&.active > div': {
        border: '2px solid #FF780F',
      }
    },
    buttonTable: {
      border: '2px solid #B6BBC2',
      width: '18px',
      height: '7px',
      borderRadius: '2px',
      margin: '1px',
    },
    buttonCard: {
      border: '2px solid #B6BBC2',
      width: '8px',
      height: '7px',
      borderRadius: '2px',
      margin: '1px',
    },
    titleSorting: {
      color: '#6E7884',
      fontSize: '16px',
      margin: 0,
      display: {
        xs: 'none',
        sm: 'block',
      }
    },
    valueSorting: {
      color: '#0D1D32',
      margin: 0,

      '& > span': {
        fontWeight: 500,
        marginLeft: '6px',
      }
    },
    pagin: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: '24px',

      '& .Mui-selected': {
        backgroundColor: '#FF780F !important',
        color: '#ffffff',

        '&:hover': {
          background: '#ec6608 !important',
          color: '#ffffff',
        }
      }
    },
    sliderWrapper: {
      width: '100%',
      position: 'absolute',
      zIndex: '1',
      top: '80px',
      left: 'auto',
      right: 0,
      background: 'linear-gradient(96.52deg, #EAF7FF 0%, #EAF0FF 100%), rgba(255, 255, 255, 0.9)',
      height: {
        xs: '143px',
        sm: '175px',
        md: '261px',
        lg: '487px'
      },
      minWidth: '325px',
    },
    slider: {
      maxWidth: {
        md: 600,
        lg: 1170
      },
      margin: '0 auto',
      height: '100%',

      '& .slick-slider': {
        height: '100%',
      },
      '& .slick-track': {
        height: '100%',
        minWidth: '310px',
      },
      '& .slick-list': {
        height: '100%',
      },
      '& .slick-slide': {
        minWidth: '310px',

        '& > div': {
          height: '100%',
        }
      },
    },
    bigTitle: {
      fontSize: {
        xs: '20px',
        sm: '32px',
        lg: '64px',
      },
      fontWeight: 900,
      color: '#6E7884',
      marginTop: {
        xs: '10px',
        sm: '25px',
        md: '55px',
        lg: '120px'
      },

      '& span': {
        color: '#FF780F'
      },
    },
    midleTitle: {
      fontSize: {
        xs: '12px',
        sm: '15px',
        md: '18px',
        lg: '32px',
      },
      fontWeight: 100,
      color: '#0D1D32',
      '& span': {
        color: '#FF780F',
        fontWeight: '700'
      },
    },
    sliderArrows: {
      width: '48px',
      height: '48px',
      background: '#FFFFFF',
      boxShadow: '0px 2px 8px rgba(13, 29, 50, 0.1)',
      borderRadius: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      transform: 'translate(0px, -50%)',
      cursor: 'pointer',
    },
    sliderAdditionally: {
      maxWidth: {
        md: 547,
        lg: 1220
      },
      margin: '-47px auto 0',
      display: {
        xs: 'none',
        sm: 'none',
        md: 'flex',
        lg: 'flex',
      },
      flexWrap: 'nowrap',
      gap: '4px',
      justifyContent: 'space-between',
      background: '#FFFFFF',
      boxShadow: '0px 10px 32px rgba(13, 29, 50, 0.08)',
      borderRadius: '16px',
      height: {
        sm: '98px',
        lg: '94px',
      },
      zIndex: '4',
      position: 'relative',

      '& .item': {
        color: '#0D1D32',
        fontSize: {
          md: '10px',
          lg: '14px',
        },
        fontWeight: 500,
        display: 'flex',
        alignItems: {
          sm: 'unset',
          md: 'unset',
          lg: 'center',
        },
        justifyContent: 'center',
        textAlign: 'center',
        flexWrap: {
          sm: 'wrap',
          lg: 'nowrap',
        },

        '&:nth-child(1)': {
          '& img': {
            marginTop: {
              md: 'md',
              lg: 'auto',
            },
            marginBottom: {
              md: 'md',
              lg: 'auto',
            },
          }
        },
        '&:nth-child(2)': {
          '& img': {
            marginTop: {
              md: 'md',
              lg: 'auto',
            },
            marginBottom: {
              md: '7px',
              lg: 'auto',
            },
          }
        },
        '&:nth-child(3)': {
          '& img': {
            marginTop: {
              md: '-11px',
              lg: 'auto',
            },
            marginBottom: {
              md: '0',
              lg: 'auto',
            },
          }
        },
        '&:nth-child(4)': {
          '& img': {
            marginTop: {
              md: '0',
              lg: 'auto',
            },
            marginBottom: {
              md: '8px',
              lg: 'auto',
            },
          }
        },
        '&:nth-child(5)': {
          '& img': {
            marginTop: {
              md: '7px',
              lg: 'auto',
            },
            marginBottom: {
              md: '4px',
              lg: 'auto',
            },
          }
        },

        '& img': {
          lg: {
            marginRight: '19px',
            marginLeft: '34px',
          }
        },
      },
    },
  }

  const { sliders } = data;

  const LIMIT_PAGE = 12;
  const [page, setPage] = useState(1)

  const changePage = (_, value) => {
    setPage(value)
  }

  const [viewPage, setViewPage] = useState('table');

  const theme = useTheme();

  const matchesSM = useMediaQuery(theme.breakpoints.only("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    if (matchesSM || matchesXS) {
      setViewPage('table')
    }
  }, [matchesSM, matchesXS]);

  const [shop, setShop] = useState({})

  const [selectedIndex, setSelectedIndex] = useState(1);

  const options = [
    { title: "вначале дороже", order: 'DESC', field: 'price' },
    { title: "вначале дешевле", order: 'ASC', field: 'price' },
    { title: "новые", order: 'ASC', field: 'operatedSince' },
  ];

  const [query, setQuery] = useState(`
    query {
      products (
        limit: ${LIMIT_PAGE},
        page: ${page},
        sort: {
          field: "${options[selectedIndex].field}"
          order: ${options[selectedIndex].order}
        }
      ) {
        metadata {
          count
          pages
        }
        collection {
          title
          quality
          price
          id
          discount
          location
          vatRate
          reserved
          address
          pricesForEachUnitEnabled
          images {
            filename
            url
          }
        }
      }

      categories (
        topOnly: true
      ) {
        id
        title
        productsCount
        subCategories {
          id
          title
          productsCount
        }
      }
    }
  `);

  useEffect(() => {
    setQuery(`
      query {
        products (
          limit: ${LIMIT_PAGE},
          page: ${page},
          sort: {
            field: "${options[selectedIndex].field}"
            order: ${options[selectedIndex].order}
          }
        ) {
          metadata {
            count
            pages
          }
          collection {
            title
            quality
            price
            id
            discount
            location
            vatRate
            reserved
            address
            pricesForEachUnitEnabled
            images {
              filename
              url
            }
          }
        }
  
        categories (
          topOnly: true
        ) {
          id
          title
          productsCount
          subCategories {
            id
            title
            productsCount
          }
        }
      }
    `)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, page])

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL_SHOP}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authentication": `${process.env.GATSBY_TOKEN_SHOP}`,
      }, body: JSON.stringify({
        query
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      setShop(data.data);
      setLoader(false);
    })
  }, [query])

  const onChangeView = view => {
    setViewPage(view)
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    setPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SampleNextArrow = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{ ...style.sliderArrows, right: 0, }}
      >
        <Box component={"img"} src={rightSlider} />
      </div>
    );
  }

  const SamplePrevArrow = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{ ...style.sliderArrows, left: 0, }}
      >
        <Box component={"img"} src={leftSlider} />
      </div>
    );
  }

  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          slidesToShow: 1,
          speed: 500
        }
      },
    ]
  };

  if (loader) {
    return (
      <noindex>
        <Box sx={{ minHeight: '600px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
          <Skeleton animation="wave" width={'160px'} />

          <Typography sx={{ fontSize: '20px', marginTop: '30px', }}>Загрузка</Typography>
        </Box>
      </noindex>
    )
  }

  return (
    <PageBox>
      <SEO pageData={{ title: 'Каталог оборудования' }} />

      <Box sx={style.sliderWrapper}>
        <Box sx={style.slider}>
          <Slider {...settings}>
            {sliders.nodes.map(slider => (
              <Box key={slider.id} sx={{ display: 'flex !important', height: '100% !important' }}>
                <Box>
                  <Typography dangerouslySetInnerHTML={{ __html: slider.bg }} sx={style.bigTitle} />
                  <Typography component="h1" dangerouslySetInnerHTML={{ __html: slider.text }} sx={style.midleTitle} />
                </Box>

                <Box sx={{ width: "90%", position: 'relative', }}>
                  <Box
                    alt="Изображение в каталоге"
                    component={GatsbyImage}
                    image={slider.img[0].localFile.childImageSharp.gatsbyImageData}
                    objectFit="contain"
                    sx={{
                      width: "100%",
                      mr: { xs: 0, md: 0, lg: "10%" },
                      minWidth: '105px',
                      maxHeight: { xs: 200, md: 256, lg: 520 },
                      position: 'absolute',
                      bottom: {
                        xs: '0px',
                        md: '13px',
                        sm: '-4px',
                        lg: '-46px',
                      },
                      ['@media (min-width: 487px) and (max-width: 510px)']: { // eslint-disable-line no-useless-computed-key
                        bottom: '-7px'
                      },
                      ['@media (min-width: 511px) and (max-width: 599px)']: { // eslint-disable-line no-useless-computed-key
                        bottom: '-17px'
                      },
                      ['@media (min-width: 1518px) and (max-width: 1520px)']: { // eslint-disable-line no-useless-computed-key
                        bottom: '-49px'
                      },
                      '& div': {
                        backgroundColor: 'transparent !important',
                      },
                      "& img": {
                        objectFit: "contain !important",
                      },
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
        <Box sx={style.sliderAdditionally}>
          <Box className={'item'}>
            <Box component={"img"} src={money} />

            Без скрытых платежей
          </Box>
          <Box className={'item'}>
            <Box component={"img"} src={clock} />

            Оформление до 2 дней
          </Box>
          <Box className={'item'}>
            <Box component={"img"} src={target} />

            100% онлайн процесс
          </Box>
          <Box className={'item'}>
            <Box component={"img"} src={checkList} />

            Скидки от поставщиков
          </Box>
          <Box className={'item'}>
            <Box component={"img"} src={percent} sx={{ marginLeft: '0 !important' }} />

            Экономия на налогах
          </Box>
        </Box>
      </Box>


      <CatalogSectionContainer sx={{ marginTop: { xs: '146px', sm: '180px', md: '364px', lg: '590px', }, position: 'relative', zIndex: 2 }}>
        <Box sx={style.wrapperPage}>
          <Box sx={style.sideBar}>
            <CatalogSectionMenu data={shop?.categories} />
            <CatalogSectionAd type={'vertical'} />
          </Box>

          <Box sx={style.content}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '26px', flexWrap: { sm: 'wrap', lg: 'nowrap' }, }}>
              <Box sx={{ display: 'flex' }}>
                <List
                  component="nav"
                  sx={{ padding: 0, }}
                >
                  <ListItem
                    aria-controls="lock-menu"
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="listbox"
                    aria-label="Сортировать"
                    button
                    id="lock-button"
                    onClick={handleClickListItem}
                    sx={{ padding: 0, }}
                  >
                    <ListItemText primary="Сортировать:" sx={style.titleSorting} />
                    <ListItemText primary={options[selectedIndex].title} sx={style.valueSorting} />
                    <ListItemText primary={<KeyboardArrowDownIcon sx={{ color: '#B6BBC2' }} />} sx={{ height: '22px', }} />
                  </ListItem>
                </List>

                <Menu
                  MenuListProps={{
                    "aria-labelledby": "lock-button",
                    role: "listbox"
                  }}
                  anchorEl={anchorEl}
                  id="lock-menu"
                  onClose={handleClose}
                  open={open}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.title}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      selected={index === selectedIndex}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box sx={style.wrapperChangeView}>
                <Box className={viewPage === 'table' ? 'active' : null} onClick={onChangeView.bind(this, 'table')} sx={style.wrapperPseudoButton}>
                  <Box sx={style.buttonTable}></Box>
                  <Box sx={style.buttonTable}></Box>
                </Box>

                <Box className={viewPage === 'block' ? 'active' : null} onClick={onChangeView.bind(this, 'block')} sx={style.wrapperPseudoButton}>
                  <Box sx={style.buttonCard}></Box>
                  <Box sx={style.buttonCard}></Box>
                  <Box sx={style.buttonCard}></Box>
                  <Box sx={style.buttonCard}></Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', }}>
              <CatalogSectionProduct data={shop?.products} view={viewPage} />

              {shop?.products?.collection?.length && (
                <Box sx={style.pagin}>
                  <Pagination count={shop?.products?.metadata?.pages} onChange={changePage} page={page} />
                </Box>
              )}
            </Box>
          </Box>

          <noindex>
            <CatalogSectionAd type={'vertical'} />
          </noindex>
        </Box>
      </CatalogSectionContainer>
    </PageBox>
  );
}

export const query = graphql`
  query IndexPageQuery {
    sliders: allStrapiSliderShops {
      nodes {
        bg
        id
        text
        img {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;